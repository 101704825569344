var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData,"query-form":_vm.queryForm},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":_vm.$l
              .getLanguageText('warehouseInStorageMain.productSkuNumber')
              .toString()},model:{value:(_vm.queryForm.productSkuNumber),callback:function ($$v) {_vm.$set(_vm.queryForm, "productSkuNumber", $$v)},expression:"queryForm.productSkuNumber"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":_vm.$l
              .getLanguageText('warehouseInStorageMain.inStorageCode')
              .toString()},model:{value:(_vm.queryForm.inStorageCode),callback:function ($$v) {_vm.$set(_vm.queryForm, "inStorageCode", $$v)},expression:"queryForm.inStorageCode"}})],1),_c('el-form-item',[_c('el-select',{attrs:{"clearable":"","filterable":"","type":"text","placeholder":_vm.$l.getLanguageText('warehouseInStorageMain.supplier').toString()},model:{value:(_vm.queryForm.supplierId),callback:function ($$v) {_vm.$set(_vm.queryForm, "supplierId", $$v)},expression:"queryForm.supplierId"}},_vm._l((_vm.supplierList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.supplierName,"value":item.id}})}),1)],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('el-divider',{staticClass:"query-form-divider"}),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.WarehouseInStorageMain.Create'),expression:"'Pages.WarehouseInStorageMain.Create'"}],staticClass:"margin-bottom-20",attrs:{"type":"success"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.createWareHousing"))+" ")])]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('basic.index').toString(),"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.inStorageCode')
            .toString(),"prop":"inStorageCode"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.purchaseCode').toString(),"prop":"purchaseCode"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.supplier').toString(),"prop":"supplierId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.fkSupplier ? row.fkSupplier.supplierName : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.totalQuantity')
            .toString(),"prop":"totalQuantity"}}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.inStorageType')
            .toString(),"prop":"inStorageType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.inStorageType === "PurchaseWarehousing" ? _vm.$l.getLanguageText( "warehouseInStorageMain.warehouseSelfLiftinPoint" ) : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.isWarehoused').toString(),"prop":"isWarehoused"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(row.isWarehoused)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.warehoused"))+" ")]):_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.notWarehoused")))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseInStorageMain.warehouse').toString(),"prop":"warehouseId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.fkWarehouse ? row.fkWarehouse.name : "")+"/"+_vm._s(row.fkWarehouseLocation ? row.fkWarehouseLocation.frame : "")+_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.frame"))+" "+_vm._s(row.fkWarehouseLocation ? row.fkWarehouseLocation.layer : "")+_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.layer"))+" "+_vm._s(row.fkWarehouseLocation ? row.fkWarehouseLocation.position : "")+_vm._s(_vm.$l.getLanguageText("warehouseInStorageMain.position"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.inStorageTime')
            .toString(),"prop":"inStorageTime"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatYearMonthDayDate")(scope.row.inStorageTime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('warehouseInStorageMain.inStorageHandler')
            .toString(),"prop":"inStorageHandlerId"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fkInStorageHandler ? scope.row.fkInStorageHandler.realName : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('basic.operation').toString()},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleDetail(scope.$index, scope.row)}}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.detail"))+" ")]),(scope.row.isWarehoused === false)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.WarehouseInStorageMain.Update'),expression:"'Pages.WarehouseInStorageMain.Update'"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.edit"))+" ")]):_vm._e()]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }