





































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { SupplierDto, WarehouseInStorageMainDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";

@Component({
  name: "WarehouseInStorageMainList",
  components: {
    PagedTableView,
  },
})
export default class WarehouseInStorageMainList extends Vue {
  queryForm = {
    productSkuNumber: undefined,
    inStorageCode: undefined,
    supplierId: undefined,
  };
  supplierList: SupplierDto[] = [];
  editId = 0;

  // 获取表数据
  fetchData(params: any) {
    return api.warehouseInStorageMain.getAll(params);
  }

  created() {
    this.fetchSupplierList();
  }

  async fetchSupplierList() {
    await api.supplier.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.supplierList = res!.items;
    });
  }

  // 跳转详情页
  handleDetail(index: number, row: WarehouseInStorageMainDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "warehouseInStorageMainDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "warehouseInStorageMainCreate",
    });
  }

  // 编辑
  handleEdit(index: number, row: WarehouseInStorageMainDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "warehouseInStorageMainEdit",
      params: { id: row.id!.toString() },
    });
  }
}
